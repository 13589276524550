import React from 'react'
import MainHeader from '../../Components/Header/MainHeader'
import './AboutUs.css'
import MainFooter from '../../Components/Footer/MainFooter'

export default function AboutUs() {
  return (
    <div className='aboutuscontainer'>
         <div className='header'>
      <MainHeader />
      </div>

      <h1  className='aboutushead'>What We Offer</h1>
      <hr/>

      <div className='Fundiapp'>
        <h3 className='fundihead'>Fundi App</h3>
        <p className='paddingtext'>The handyman uses this application to check for service requests. 
            The fundi can validate and appraise the problem based on the customer’s 
            description and the images given. With these, the handyman is confident 
            enough to create an invoice detailing the materials needed and the cost of the work.
            </p>
                <h6>Types of handymen</h6>
               <p>
                Painter<br/>
                Household Electronics<br />
                Fumigation Expert<br />
                Electrician<br />
                Plumber<br />
                Glass / Mirror /Window Fixer<br />
                Carpenter<br />
                Welder - Metal Fabricator<br />
                Locks and Doors<br />
                Tiler<br />
                Gypsum Expert<br />
                Waterproofing<br />
                Roofer<br />
                Cladding /Mazeras<br />
                Parking Sheds<br />
                External Paver<br />
                Terrazo<br />
                Landscaper<br />
                </p>

      </div>
      <div className='rekebisharepair' >
        <h3>Rekebisha Repair Bureu</h3>
        <p className='paddingtext'>Rekekebisha ‘Digitised Construction’ App is major Online channel via which 
            fundis can register with the company on the Rekebisha Website. The Rekebisha 
            corporation can check the credibility and craftsmanship qualification of the 
            handymen through registration in one of our various centers. This entails showing 
            proof of a good conduct certificate, national Identification card and a 100 Kes, 
            payment fee done through   in order to confirm the Official names of the Handyman. 
            The website allows tradespeople and experts of various specializations to promote 
            their work by sharing videos and photos, as well as define their profile in a 
            professional manner. With these we are able to build authenticity and trust amongst 
            our users from the Initial stage.</p>
            <p>

                NCA online registration<br />
                Good Conduct application/ verification<br />
                CV/Vendor profile creation/ on-boarding process<br />
                Driving License application and renewal<br />
                Passport application and appointments<br />
                Job placement through RekebisahApp<br />
                Storage Depot/Retail store for Dalhin Efficiamax<br />
                Construction Tenders
                </p>
      </div>

      <div className='dotdot'>
        <h3>Dot Dot App</h3>
        <p className='paddingtext'>
        Dot Dot application is used by sellers to validate consumer requests for DotDot 
        commodities. The vendor can check the available requests, the quantity of the item 
        being requested, the price of the commodity, how distant the consumer is, and how 
        long it will take for the delivery to take place. For smooth running of the business, 
        the vendor cannot accept a new delivery request if there is a pending request in the 
        application. The system works on one request/ delivery at a time.</p>
        <h6>Dotdot App Products And services:</h6>

        <p>
        Fuel and Puncture services<br />
        Breakdown Services<br />
        Efficiamax 7ml<br />
        Efficiamax 6 pack (7ml)<br />
        Efficiamax 240ml<br />
        Ambulance Services<br />
        </p>
      </div>

      <div className='findhardware'>
        <h3>Find Hardware</h3>
        <p className='paddingtext'>
        Find Hardware application is used by sellers to validate customers requests 
        for building materials.
            </p>
            <h6 style={{color: "white"}}>Categories:</h6>

            <p>
            General Hardware <br />
            2Pipes and fittings hardware <br />
            Electrical Hardware<br />
            Timber and Gypsum hardware<br />
            Metal hardware<br />
            Paints and Cement Hardware<br />
            Locks and Doors hardware<br />
            Aluminium, Glass and mirrors hardware<br />
            Tiles, Carpet and Upholstery hardware<br />
            Furniture and Fittings hardware<br />
            Concrete pipes and culverts<br />
            External paving finishes and blocks
        </p>
      </div>
      <MainFooter />
    </div>
  )
}
