import React, { useState } from 'react';
import HandWithSpanner from '../../assets/hand-with-spanner.png';
import AboutUs from '../../assets/About Us.png';
import Fundi from '../../assets/fundi.png';
import BrokenSink from '../../assets/broken-sink.png';
import FixedSink from '../../assets/fixed-sink.png';
import PhoneHand from '../../assets/phone-hand.png';
import Download from '../../assets/Download-button.png'
import MainHeader from '../../Components/Header/MainHeader';
import MainFooter from '../../Components/Footer/MainFooter';
import rfundiIcon from '../../assets/R-Fundi.png'
import dotdotIcon from '../../assets/dotdot.png'
import rekPropertyIcon from '../../assets/rekebisha repair.png'
import hardwarIcon from '../../assets/hardware.png'

export default function HomePage() {

  const handymen = [
    "LPG Cooking Gas",
    "Key Cutting, locks and doors",
    "Interior Designer",
    "General Builder(Cost estimator)",
    "Painter",
    "Household Electronics Expert",
    "Fumigation Expert",
    "Electrician and Electrical fittings",
    "Plumber and Sanitary Fittings",
    "Glass / Mirror / Window fixer",
    "Aluminium and Fit - outs",
    "Carpenter",
    "Tiles - Ceramic, Granite",
    "Gypsum Expert",
    "Roofer",
    "Mazeras / Cladding",
    "Metal Expert - Welder",
    "Locks and Doors",
    "Waterproofing Expert",
    "External Paver",
    "Terrazzo",
    "Landscaper",
    "Parking Shades",
    "Window Blinds, Curtains, Boxes, Rods, Films and Tints",
    "Advertising Signages and Boxes",
    "Carpet and Upholstery",
    "Furniture and Fittings",
    "Plastic Pipes and Fittings",
    "Concrete Pipes and Culverts"
  ];

  const propertyServices = [
    "Safe Parking Space (Yard - Short term)",
    "Safe Parking Space (Office Block - Short term)",
    "Safe Parking Space (Apartment - Short term)",
    "Safe Parking Space (Yard - Long term)",
    "Safe Parking Space (Office Block - Long term)",
    "Safe Parking Space (Apartment - Long term)",
    "Residential Apartment - Studio",
    "Residential Apartment - One B/ Room",
    "Residential Apartment - Two B/ Room",
    "Residential Apartment - Three B/Room",
    "Residential Apartment - Four B/Room Plus",
    "Residential Apartment - Whole Building",
    "Residential Stand-Alone - Two B/Room",
    "Residential Stand-Alone - Three B/Room",
    "Residential Stand-Alone - Four B/Room",
    "Residential Stand-Alone - Five B/Room Plus",
    "Commercial Space - Stand-Alone",
    "Commercial Space - Office Block",
    "Commercial Space - Residential Apartment",
    "Commercial Space - Shopping Mall",
    "Retail Space - Stand-Alone",
    "Retail Space - Office Block",
    "Retail Space - Residential Apartment",
    "Retail Space - Shopping Mall",
    "Office Space - Stand-Alone",
    "Office Block - Office space",
    "Office Block - Meeting/ Board Rooms",
    "Office Block - Whole Building",
    "Industrial Space - Stand-Alone",
    "Industrial Space - Common Park",
    "Warehouse/ Storage - Stand-Alone",
    "Warehouse/ Storage - Office Block",
    "Warehouse/ Storage - Industrial Park",
    "Warehouse/ Storage - Apartment Building",
    "Warehouse/ Storage - Commercial Mall",
    "Events/ Grounds - Indoor",
    "Events/ Grounds - Outdoor",
    "Events /Grounds - Blended In/Outdoor",
  ];

  const hardwareCategories = [
    "LPG Cooking Gas",
    "Key Cutting,locks and doors",
    "Interior Design art store",
    "General Hardware",
    "Electrical Hardware",
    "Plastic Pipes and Fittings Hardware",
    "Concrete Pipes and Culverts",
    "Tiles and Sanitary Fittings Hardware",
    "Paints and Cement Hardware",
    "General Metal Hardware",
    "Locks and Doors Hardware",
    "Household Electronics Hardware",
    "Agrovet/ Fumigation Expert",
    "Glass/ Mirror/ Window Hardware",
    "Aluminium and Fit- outs Hardware",
    "Timber and Gypsum Hardware",
    "Roofing Hardware",
    "Mazeras/ Cladding Hardware",
    "External Paver Hardware",
    "Window Blinds, Curtains, Boxes, Rods, Films and Tints",
    "Advertising Signages and Boxes",
    "Carpet and Upholstery",
    "Furniture and Fittings",
  ];

  const dotDotServices = [
    "Emergency First Aid Responder (Medical Practitioners registered by KMPDU or Nurses Council of Kenya)",
    "Emergency Motor Breakdown Services (PSV or Police Clearance Certificate Holders)",
    "Reliable General Handymen (PSV or Police Clearance Certificate Holders)",
    "Reliable General Courier/ Package Delivery (PSV or Police Clearance Certificate Holders)",
    "Efficiamax Agent (PSV or Police Clearance Certificate Holders)",
  ];

  return (
    <div className='flex flex-col min-h-screen'>
      <MainHeader />
      <div className='flex flex-col flex-1'>

        {/* Hero Section */}
        <div id='hero' className='grid sm:grid-cols-2 items-center justify-between grid-cols-1 gap-12 sm:px-20 px-6 py-20'>
          <div className='flex flex-col gap-6'>
            <h2 className='sm:text-4xl text-3xl text-[#173044] font-semibold capitalize'>Find a qualified fundi in your area(mtaani)</h2>
            <div className='text-sm'>Rekebisha <b className='text-red-600'>'Digitised Construction'</b> App is the ONLY online channel via which Fundis and Technicians can register and get gigs in real-time, online. <br></br> <br></br>Our App is made to help Fundis and Technicians gain access to clients easily and efficiently through the use of Technology.</div>
            <a className='mt-4 max-w-48' href="https://play.google.com/store/apps/details?id=com.rekebisha.RekebishaClient&pcampaignid=web_share" target='_blank'>
              <img src={Download} className='w-48 h-auto'></img>
            </a>
          </div>
          <div className='flex items-center justify-center'>
            <img src={HandWithSpanner} alt='banner' className='sm:w-96 w-72 h-auto'></img>
          </div>
        </div>

        {/* How it works section */}

        <div id="how-it-works" className='flex flex-col items-center justify-center bg-[#2b5989] py-12 sm:px-20 px-6 gap-12'>
          <div className='sm:text-3xl text-2xl font-semibold uppercase text-center text-white'>How it works</div>
          <div className='grid sm:grid-cols-3 grid-cols-1 gap-12'>
            <div className='flex flex-col items-center justify-center gap-2'>
              <img alt="image" src={BrokenSink} className='w-36 h-auto'></img>
              <div className='text-sm text-center text-white'>Stressed client with a problem at home/office, downloads Rekebisha App</div>
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
              <img alt="image" src={PhoneHand} className='w-36 h-auto'></img>
              <div className='text-sm text-center text-white'>The client describes the problem and posts to find a fundi in the area</div>
            </div>
            <div className='flex flex-col items-center justify-center gap-2'>
              <img alt="image" src={FixedSink} className='w-36 h-auto'></img>
              <div className='text-sm text-center text-white'>A qualified technician gets the request and arrives to fix the problem</div>
            </div>
          </div>
        </div>

        {/* About Us Section */}

        <div id="about-us" className='sm:px-20 px-6 grid sm:grid-cols-2 grid-cols-1 gap-6 py-20'>
          <div className='flex flex-col gap-4'>
            <h2 className='text-3xl text-[#173044]  font-semibold'>Who we are</h2>
            <div className='text-sm'>Rekebisha Repairs is a Registered 24/7 quick response digital solution service provider for Dwellings houses, Business complexes and Institutions.</div>
            <div className='text-sm'>We pride in our skilled and experienced technical human resource team that provide top notch advisory and consulting/contracting services in the built environment repairs and emergency services sector.</div>
            <div className='text-sm'>Rekebisha Repairs confirms and verifies, the expertise and qualifications of the operators through a rigorous registration/ onboarding process conducted at our bureau.</div>
          </div>

          <div className='flex items-center justify-center'>
            <img src={AboutUs} alt='about' className='sm:w-96 w-72 h-auto'></img>
          </div>

        </div>


        {/* Offerings Section */}
        <div id="platforms" className='sm:px-12 px-6 flex flex-col py-12 gap-12 bg-gray-50'>
          <h2 className='text-3xl text-[#173044] text-center  font-semibold'>Our platforms</h2>
          <div className='grid sm:grid-cols-2 grid-cols-1 items-start sm:px-20  gap-6'>
            <div className='border gap-4 border-gray-300 rounded-lg flex flex-col py-12 px-10 hover:bg-white bg-gray-50 shadow-lg'>
              <h2 className='text-2xl text-[#173044] text-center capitalize font-semibold'>R-Fundi App</h2>
              <div className='flex flex-col items-center'>
                <img className='w-20 h-auto text-center' src={rfundiIcon}></img>
              </div>
              <div className='text-xs'>The handyman uses this application to check for service requests. The fundi can validate and appraise the problem based on the customer's description and the images uploaded. With these, the handyman is confident enough to accept a request based on the client's proposed budget which is an indicator of the work involved. Clients are advised to budget appropriately in order to receive service from the best quality operators from within the Rekebisha E -Community.</div>
              <div className='font-semibold text-gray-400'>Handymen Include</div>
              <ul className='list-disc px-4 space-y-2'>
                {handymen.map((e, i) => {
                  return <li className='text-sm' key={i}>{e}</li>
                })}
              </ul>
            </div>

            <div className='border gap-4 border-gray-300 rounded-lg flex flex-col py-12 px-10 hover:bg-white bg-gray-50 shadow-lg'>
              <h2 className='text-2xl text-[#173044] text-center capitalize font-semibold'>Find Hardware</h2>
              <div className='flex flex-col items-center'>
                <img className='w-20 h-auto text-center' src={hardwarIcon}></img>
              </div>
              <div className='text-xs'>Find Hardware application is used by hardware operators to validate customer requests for building materials. This segment is applicable for establishments that offer delivery services for their products. The client is able to view products from hardware stores that are within their vicinity under the following categories:</div>
              <ul className='list-disc px-4 space-y-2'>
                {hardwareCategories.map((e, i) => {
                  return <li className='text-sm' key={i}>{e}</li>
                })}
              </ul>
            </div>

            <div className='border gap-4 border-gray-300 rounded-lg flex flex-col py-12 px-10 hover:bg-white bg-gray-50 shadow-lg'>
              <h2 className='text-2xl text-[#173044] text-center capitalize font-semibold'>Rekebisha Find Property</h2>
              <div className='flex flex-col items-center'>
                <img className='w-20 h-auto text-center' src={rekPropertyIcon}></img>
              </div>
              <div className='text-xs'>This application is used by potential buyers and tenants to view properties that have been posted by credible (verified) and registered valuers or property agents. The property seeker shall have a choice to view listings offered that are within their vicinity under the following categories:</div>
              <div className='font-semibold text-gray-400'>Services Include</div>
              <ul className='list-disc px-4 space-y-2'>
                {propertyServices.map((e, i) => {
                  return <li className='text-sm' key={i}>{e}</li>
                })}
              </ul>
            </div>

            <div className='border gap-4 border-gray-300 rounded-lg flex flex-col py-12 px-10 hover:bg-white bg-gray-50 shadow-lg'>
              <h2 className='text-2xl text-[#173044] text-center capitalize font-semibold'>Dot Dot App</h2>
              <div className='flex flex-col items-center'>
                <img className='w-20 h-auto text-center' src={dotdotIcon}></img>
              </div>
              <div className='text-xs'>This is an EMERGENCY service provider Application. This application offers the Rekebisha community an opportunity to contact credible (verified) and registered medical practitioners when in need of FIRST AID services thus providing care to preserve life, prevent the condition from worsening, or to promote recovery until medical services arrive.</div>
              <ul className='list-disc px-4 space-y-2'>
                {dotDotServices.map((e, i) => {
                  return <li className='text-sm' key={i}>{e}</li>
                })}
              </ul>
            </div>
          </div>
        </div>

        {/* Contact Us */}

        <div id="how-it-works" className='flex flex-col items-center justify-center bg-[#e7f4d7] py-12 sm:px-20 px-6 gap-2'>
          <div className='text-lg font-semibold uppercase text-center text-black pb-4'>Contact Us</div>
          <div className='text-sm text-center text-black'>Buruburu Phase 4, City Church Stage.</div>
          <div className='text-sm text-center text-black'>Tel: <a href='tel:0733212124' target='_blank' className='underline font-semibold text-blue-600'>0733212124</a></div>
          <div className='text-sm text-center text-black'>Email: <a href='mailto:rekebisharepairs@gmail.com' target='_blank' className='underline font-semibold text-blue-600'>rekebisharepairs@gmail.com</a></div>

        </div>

        <div id="download-app" className='grid sm:grid-cols-2 grid-cols-1 items-center justify-center pt-12 sm:px-20 px-6 sm:pb-0 pb-12 sm:gap-4 gap-0'>
          <div className='flex flex-col items-center'>
            <img src={Fundi} className='w-48 h-auto'></img>
          </div>
          <div className='flex flex-col items-start gap-4'>
            <h2 className='font-bold text-3xl sm:text-3xl text-[#173044]'>Download <span className='text-red-600'>Rekebisha</span> App</h2>
            <div>Describe your problem and we get a fundi near you in a jiffy!</div>
            <a className='mt-4 max-w-48' href="https://play.google.com/store/apps/details?id=com.rekebisha.RekebishaClient&pcampaignid=web_share" target='_blank'>
              <img src={Download} className='w-48 h-auto'></img>
            </a>
          </div>
        </div>

      </div>
      <MainFooter />
    </div>
  );
}
