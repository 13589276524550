// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCONY-6Xd6gik_CFDjxQ7cGzHMR9XrofBY",
  authDomain: "rekebishawebsite-f0016.firebaseapp.com",
  projectId: "rekebishawebsite-f0016",
  storageBucket: "rekebishawebsite-f0016.appspot.com",
  messagingSenderId: "474413922074",
  appId: "1:474413922074:web:4cb43a83aec67c9e8e5ea3",
  measurementId: "G-HB8B6YRGR3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = firebase.firestore();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export const auth = firebase.auth()