import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "flowbite";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Screens/HomesPage/HomePage';
import Login from './Screens/Auth/Login';
import EditablePortfolio from './Screens/Portfolio/EditablePortfolio';
import UploadScreen from './Screens/UploadScreen/UploadScreen';
import SharedPortfolio from './Screens/Portfolio/SharedPortfolio';
import AboutUs from './Screens/AboutUs/AboutUs';
import PasswordReset from './Screens/Auth/PasswordReset';
import ContactUs from './Screens/ContactUs';
import FindFundi from './Screens/FindFundi';
import FindHarware from './Screens/FindHardware';
import FindRental from './Screens/FindRental';
import DotDot from './Screens/DotDot';
import TandC from './Components/Terms&Conditions/TandC';
import Error404 from './Screens/404/Error404';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/editableportfolio" element={<EditablePortfolio />} />
        <Route path="/uploadscreen" element={<UploadScreen />} />
        <Route path="/find-fundi/shared/:sharedId" element={<SharedPortfolio />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/contact-us" element={< ContactUs />} />
        <Route path="/find-fundi" element={< FindFundi />} />
        <Route path="/find-hardware" element={< FindHarware />} />
        <Route path="/find-rental" element={< FindRental />} />
        <Route path="/dot-dot" element={<DotDot />} />
        <Route path="/terms-and-conditions" element={<TandC/>} />
        <Route path="/*" element={<Error404/>} />
      </Routes>
    </Router>
  );
}

export default App;
