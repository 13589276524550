import React, { useState, useEffect } from 'react';
import MainHeader from '../../Components/Header/MainHeader';
import { Link, useNavigate } from 'react-router-dom';
import MainFooter from '../../Components/Footer/MainFooter';
import { auth } from '../../Database/RekebishaAdmin/config';

export default function SignUp() {
  const navigate = useNavigate();

  const [emailid, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        navigate('/editableportfolio');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {

    if (!emailid) {
      setEmailError(true);
    }

    if (!password) {
      setPasswordError(true);
    }

    if (emailid && password) {
      auth
        .signInWithEmailAndPassword(emailid, password)
        .then(userCredentials => {
          const user = userCredentials.user;
          navigate('/editableportfolio');
        })
        .catch(error => alert(error.message))
    }

  }

  return (
    <div className='flex flex-col min-h-screen'>
      <MainHeader />
      <div className='flex flex-col justify-center items-center flex-1 py-20 px-4'>
        <div className='sm:w-1/3 w-full bg-gray-100 rounded py-4 px-6 flex flex-col gap-4'>
          <h1 className='text-lg font-semibold'>Sign In</h1>

          <div className='flex flex-col gap-2'>
            <input
              value={emailid}
              onChange={(e) => setEmailId(e.target.value)}
              type='email'
              className='py-2 px-4 rounded bg-white border border-gray-500'
              required
              placeholder='Email' />
            {emailError && <div className='text-sm text-red-600'>Enter email addresss</div>}
          </div>
          <div className='flex flex-col gap-2'>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              className='py-2 px-4 rounded bg-white border border-gray-500'
              required
              placeholder='Password'
            />
            {passwordError && <div className='text-sm text-red-600'>Enter password</div>}
          </div>

          <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row gap-2 items-center py-1'>
              <input type='checkbox' className='w-4 h-4 cursor-pointer' onChange={togglePasswordVisibility}></input>
              <label className='text-sm'>Show Password</label>
            </div>
          </div>
          <button onClick={handleLogin} className='flex bg-[#173044] text-white py-2 rounded justify-center'>Sign In</button>
        </div>

        <Link to='/password-reset' className='py-6 cursor-pointer hover:text-blue-800 hover:underline'>Forgotten Password?</Link>

      </div>
      <MainFooter />
    </div>
  );
}

