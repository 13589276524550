import React, { useState } from 'react';
import MainHeader from '../../Components/Header/MainHeader';
import { Link } from 'react-router-dom';
import MainFooter from '../../Components/Footer/MainFooter';
import { auth } from '../../Database/config';

export default function PasswordReset() {
    const [email, setEmail] = useState('');
    const [resetError, setResetError] = useState(null);
    const [resetSuccess, setResetSuccess] = useState(false);

    const handlePasswordResetRequest = async () => {
        try {
            await auth.sendPasswordResetEmail(email);
            setResetSuccess(true);
            setResetError(null);
        } catch (error) {
            setResetSuccess(false);
            setResetError(error.message);
        }
    };

    return (
        <div className='flex flex-col min-h-screen'>
            <MainHeader />
            <div className='flex flex-col justify-center items-center flex-1 py-20 px-4'>
                <div className='sm:w-1/3 w-full bg-gray-100 rounded py-4 px-6 flex flex-col gap-4'>
                    <h1 className='text-lg font-semibold'>Password Reset</h1>
                    <div className='text-sm'>An email with a password reset link will be sent to your email. Check your email for the reset password link.</div>
                    <div className='flex flex-col gap-2'>
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                            className='py-2 px-4 rounded bg-white border border-gray-500'
                            required
                            autoFocus
                            placeholder='Email'
                        />
                        {resetError && <div className='text-sm text-red-600'>{resetError}</div>}
                    </div>
                    {resetSuccess ? (
                        <div className='text-sm text-green-600'>Password reset email sent successfully.</div>
                    ) : (
                        <button onClick={handlePasswordResetRequest} className='flex bg-[#173044] text-white py-2 rounded justify-center'>
                            Reset Password
                        </button>
                    )}
                </div>
                <Link to='/login' className='py-6 cursor-pointer hover:text-blue-800 hover:underline'>
                    Remembered Password? Sign In
                </Link>
            </div>
            <MainFooter />
        </div>
    );
}
