import React from 'react'
import footerLogo from '../../assets/logo-footer.png'
import facebook from '../../assets/ic_sharp-facebook.png'
import instagram from '../../assets/insta.png'
import twitter from '../../assets/twitter.png'
import { Link } from 'react-router-dom';

export default function MainFooter() {

    return (
        <div className='flex flex-row flex-wrap gap-6 bg-[#8CC740] items-center justify-between sm:px-12 px-6 py-4'>
            <div className='flex flex-row flex-nowrap items-center gap-2'>
                <img src={footerLogo} alt="My Image" className='w-12 h-12' />
                <p className='text-sm'>©{new Date().getFullYear()}. Rekebisha. All Rights Reserved</p>
            </div>

            <div className='flex flex-row items-center text-xs gap-6'>
                <nav>
                    <Link to='#'>
                        Help Center
                    </Link>
                </nav>
                <nav>
                    <Link to='#'>
                        FAQs
                    </Link>
                </nav>
                <nav>
                    <Link to='/terms-and-conditions'>
                        Terms of Use
                    </Link>
                </nav>
            </div>
            
            <div className='flex flex-row items-center sm:gap-4 gap-8 sm:pb-0 pb-6'>
                <p className='font-semibold text-sm'>Follow Us</p>
                <img src={facebook} alt="Facebook" className='text-white w-6 h-6'/>
                <img src={instagram} alt="Instagram" className='text-white w-6 h-6' />
                <img src={twitter} alt="Twitter" className='text-white w-6 h-6' />
            </div>
        </div>
    )
}
