import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";


const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyDnQjdg7HNuO5CQhCKD2DS8R__hr2f4_ys",
  authDomain: "rekebishafundi-94607.firebaseapp.com",
  projectId: "rekebishafundi-94607",
  storageBucket: "rekebishafundi-94607.appspot.com",
  messagingSenderId: "27983586788",
  appId: "1:27983586788:web:2615cfffbdb199efd93f1"
}, 'firebaseConfig');


// Firebase Config for Rekebisha Website
export const db = firebaseConfig.firestore();
export const auth = firebaseConfig.auth();
export const storage = firebaseConfig.storage();