import React, { useState } from 'react';
import './UploadScreen.css';
import { BsImageFill } from 'react-icons/bs'
import { GrTextAlignFull } from 'react-icons/gr'
import MainHeader from '../../Components/Header/MainHeader';
import { db, storage, auth } from '../../Database/RekebishaAdmin/config';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';

export default function UploadScreen() {

  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [disableButton, setDisabledButton] = useState(false);

  const handleImageChange = (e) => {
    e.preventDefault();
    let pickedfile;
    if (e.target.files && e.target.files.length > 0) {
      pickedfile = e.target.files[0];
      setImage(pickedfile);
    }
  }

  const handleUpload = (e) => {
    e.preventDefault();

    if (!image || !title || !description) {
      alert("Please fill in all fields before uploading.");
      return;
    }

    setDisabledButton(true);

    const serialNumber = Math.floor(100000 + Math.random() * 9000).toString();

    const uploadTask = storage.ref("PostImage")
      .child(serialNumber)
      .put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (err) => {
        console.log(err);
      },
      () => {
        storage.ref("PostImage")
          .child(serialNumber)
          .getDownloadURL()
          .then((imageUrl) => {
            db.collection('FundiAppUsers')
              .doc(auth.currentUser.uid)
              .collection('Posts')
              .add({
                title: title,
                description: description,
                imageUrl: imageUrl,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              })

            setTitle("");
            setDescription("");
            setImage(null);

            navigate('/editableportfolio');

            setDisabledButton(false);

          })

      }
    )
  }



  return (
    <div className='uploadScreen'>
      <MainHeader />
      <div className='uploadBody'>
        <div className='uploadCard'>
          <h1 className='uploadTitle'>Add Portfolio</h1>
          <div className='uploadDescription'>
            <GrTextAlignFull />
            <textarea value={title} onChange={(e) => setTitle(e.target.value)}
              className='uploadTextarea' placeholder='Type Title' />
          </div>
          <div className='uploadImage'>
            <h5 className='ImageWord'><BsImageFill /> Add Image</h5>
            <input onChange={handleImageChange} className='uploadImageBtn' type='file' />
          </div>
          <div className='uploadDescription'>
            <GrTextAlignFull />
            <textarea value={description} onChange={(e) => setDescription(e.target.value)}
              className='uploadTextarea' placeholder='Type Description' />
          </div>
          <button className={`${disableButton? 'bg-gray-400 text-gray-500 cursor-not-allowed': 'bg-green-500 text-white cursor-pointer'} px-4 py-2 mt-5 rounded-xl`} onClick={handleUpload}>Upload Image</button>
        </div>
      </div>
    </div>
  )
}
