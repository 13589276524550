import { useEffect, useState } from 'react';
import { auth, db } from '../../Database/RekebishaAdmin/config';
import { Link } from 'react-router-dom';

const UserProfile = () => {

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [industry, setIndustry] = useState("");
    const [phonenumber, setPhoneNumber] = useState(0);
    const [professionalbody, setProfessionalBody] = useState("")
    const [email, setEmail] = useState("");
    const [userid, setUserId] = useState("");
    const [profession, setProfession] = useState("");
    const [address, setAddress] = useState("");
    const [overview, setOverView] = useState("");
    const [avatarurl, setAvatarUrl] = useState('');

    useEffect(() => {
        const getUserDetails = async () => {
            try {
                const doc = await db.collection('FundiAppUsers').doc(auth.currentUser.uid).get();
                if (doc.exists) {
                    const data = doc.data();
                    const firstname = data.firstname;
                    const lastname = data.lastname;
                    const industry = data.industry;
                    const phonenumber = data.phonenumber;
                    const professionalBody = data.professional_body;
                    const userId = doc.id;
                    const email = data.email;
                    const profession = data.profession;
                    const address = data.address;
                    const overview = data.overview;
                    const avatarurl = data.profileimage;

                    setAvatarUrl(avatarurl);
                    setFirstName(firstname);
                    setLastName(lastname);
                    setIndustry(industry);
                    setPhoneNumber(phonenumber);
                    setProfessionalBody(professionalBody);
                    setUserId(userId);
                    setEmail(email);
                    setProfession(profession);
                    setAddress(address);
                    setOverView(overview);

                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting user details:', error);
            }
        };

        getUserDetails();

    }, []);

    return (
        <a href='/editableportfolio'>
            {avatarurl ?
                <img src={avatarurl} className='w-12 h-12 rounded-full object-cover' alt={`${firstname} ${lastname}`}></img>
                :
                <div className="flex items-center justify-center w-full h-12 bg-gray-300 sm:w-12 rounded-full dark:bg-gray-700">
                    <svg className="w-12 h-12 rounded-full text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
            }
        </a>
    );
}

export default UserProfile;