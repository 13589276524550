import React, { useEffect } from "react";
import MainHeader from "../../Components/Header/MainHeader";
import MainFooter from "../../Components/Footer/MainFooter";
import { db } from "../../Database/RekebishaAdmin/config";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Rating } from "primereact/rating";

export default function SharedPortfolio(props) {
  const { sharedId } = useParams();

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phonenumber, setPhoneNumber] = useState(0);
  const [professionalbody, setProfessionalBody] = useState("");
  const [profession, setProfession] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [avatarurl, setAvatarUrl] = useState("");
  const [rating, setRating] = useState(0);
  const [subcounty, setSubCounty] = useState("");
  const [competenceRating, setCompetenceRating] = useState(null);
  const [certificationRating, setCertificationRating] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await db
        .collection("FundiAppUsers")
        .doc(sharedId)
        .collection("Posts")
        .get();
      const items = snapshot.docs.map((doc) => doc.data());
      setPortfolioItems(items);
    };
    fetchData();
  }, []);

  /*Get the users Information*/
  const getUserDetails = async () => {
    try {
      const doc = await db.collection("FundiAppUsers").doc(sharedId).get();

      if (doc.exists) {
        const data = doc.data();
        const firstname = data.firstname;
        const lastname = data.lastname;
        const phonenumber = data.phonenumber;
        const professionalBody = data.professionalbody;
        const profession = data.profession;
        const email = data.email;
        const address = data.countylocation;
        const avatarurl = data.profileimage;
        const rating = data.competenceRating;
        const subcounty = data.subcounty;
        const competenceRating = data.competenceRating;
        const certificationRating = data.certificationRating;

        setAvatarUrl(avatarurl);
        setFirstName(firstname);
        setLastName(lastname);
        setProfession(profession);
        setPhoneNumber(phonenumber);
        setProfessionalBody(professionalBody);
        setEmail(email);
        setCategory(category);
        setAddress(address);
        setRating(rating);
        setSubCounty(subcounty);
        setCompetenceRating(competenceRating);
        setCertificationRating(certificationRating);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting user details:", error);
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div className="flex flex-col bg-gray-100">
      <MainHeader />
      <div className="flex flex-row flex-wrap gap-4 py-10 sm:px-12 px-4">
        <div className="flex flex-row flex-wrap gap-4 sm:w-1/4 w-full">
          <div className="relative">
            <div className="sticky top-5 flex flex-col gap-2">
              <div className="flex flex-col items-center w-full gap-4 p-4 bg-white border border-gray-300 rounded shadow">
                {avatarurl ? (
                  <img
                    src={avatarurl}
                    alt="Image"
                    className="rounded-full object-contain bg-gray-50 shadow border-gray-500 h-28 w-28 my-2"
                  ></img>
                ) : (
                  <div
                    role="status"
                    className="rounded-full object-contain bg-gray-400 shadow border-gray-500 h-32 w-32 mb-2 animate-pulse"
                  ></div>
                )}
                <div className="flex flex-col items-center gap-2">
                  {firstname && lastname ? (
                    <h6 className="text-xl text-black font-semibold capitalize">{`${firstname} ${lastname}`}</h6>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                  {profession ? (
                    <div className="text-[11px] bg-gray-600 py-1 px-2 rounded font-semibold capitalize text-white">
                      {profession}
                    </div>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                  {professionalbody ? (
                    <h6 className="text-sm text-center text-black capitalize">
                      {professionalbody}
                    </h6>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                  <Rating
                    className="pb-6"
                    value={rating}
                    disabled
                    cancel={false}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 p-6 bg-white border border-gray-300 rounded shadow w-full">
                <div className="flex flex-col gap-4">
                  <h6 className="text-xs text-gray-300 uppercase">
                    Rating Details
                  </h6>
                  <div className="flex flex-row flex-nowrap items-center gap-2">
                    <div className="">Certification: </div>
                    <Rating value={certificationRating ? certificationRating : 0 } disabled cancel={false} />
                  </div>
                  <div className="flex flex-row flex-nowrap items-center gap-2">
                    <div className="">Competence: </div>
                    <Rating value={competenceRating} disabled cancel={false} />
                  </div>
                  <div className="flex flex-row flex-nowrap items-center gap-2">
                    <div className="">Courtesy: </div>
                    <Rating value={0} disabled cancel={false} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 p-6 bg-white border border-gray-300 rounded shadow w-full">
                <div className="flex flex-col gap-2">
                  <h6 className="text-xs text-gray-300 uppercase">
                    Contact Details
                  </h6>
                  {phonenumber ? (
                    <h6 className="text-black lowercase">
                      {phonenumber}
                    </h6>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                  {email ? (
                    <h6 className="text-black lowercase">{email}</h6>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-4 p-6 bg-white border border-gray-300 rounded shadow w-full">
                <div className="flex flex-col gap-2">
                  <h6 className="text-xs text-gray-300 uppercase">Location</h6>
                  {address ? (
                    <h6 className="text-black capitalize">
                      County: {address}
                    </h6>
                  ) : (
                    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
                  )}
                  {subcounty && (
                    <h6 className="text-black capitalize">
                      Sub County: {subcounty}
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          {portfolioItems.length != 0 ? (
            <div className="flex flex-row gap-4 flex-wrap">
              {portfolioItems.map((items) => (
                <div className="flex flex-col">
                  <figure className="relative max-w-sm transition-all duration-300 cursor-pointer">
                    <a href={items.imageUrl} target="_blank">
                      <img
                        className="rounded w-72 h-72 object-cover"
                        src={items.imageUrl}
                        alt=""
                      />
                    </a>
                    <figcaption className="absolute bg-black opacity-70 w-full px-4 py-2 text-lg text-white bottom-0">
                      <h5 className="capitalize truncate">{items.title}</h5>
                    </figcaption>
                  </figure>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center text-center gap-4 bg-white rounded border py-8 border-gray-300 shadow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-24 h-24 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
              <h2 className="text-lg text-gray-500">No content found!</h2>
            </div>
          )}
        </div>
      </div>
      <MainFooter />
    </div>
  );
}
