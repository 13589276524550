import React from 'react';
import MainHeader from '../Header/MainHeader';
import MainFooter from '../Footer/MainFooter';

export default function TandC() {
  return (
    <div className='flex flex-col min-h-screen bg-gray-100'>
      <MainHeader/>
        <div className='py-10 sm:px-12 px-4'>
        <h1 className='text-xl font-bold py-4'>
            Terms and Conditions
        </h1>
        <p>
        <div className='text-lg font-semibold'>Terms and Conditions for REKEBISHA Users</div>

These General Terms and Conditions set out the terms and conditions applying to and governing the usage of the Rekebisha App - technology which connects clients with fundisand other service providers to help them ease their daily activities  more efficiently.

The term “us” or “we” refers to REKEBISHA  a private limited company incorporated and registered under the laws of Republic of KENYA with registration code ...........................    ., or other REKEBISHA REPAIRS group company or cooperation partner where REKEBISHA Services are not provided by REKEBISHA . The list of REKEBISHA companies and partners is available at www.rekebisha.com

In order to use REKEBISHA app you must agree to the terms and conditions that are set out below:


<div className='text-lg font-semibold py-4'>1. Using the REKEBISHA app</div>

1.1	REKEBISHA provides an information society service through REKEBISHA app that enables mediation of the requests for construction and other services between the clients and professionals,however  REKEBISHA does not provide construction or repair services. Services in the App are provided by professionals registered under Rekebisha bureaus  under a contract (with you) for the provision of respective professional services. Fundis provide construction /repair  services on an independent basis (either in person or via a company) as economic and professional service providers. REKEBISHA is not responsible in any way for the fulfilment of the contract entered into between the CLIENT(you) and the Fundi. Disputes arising from consumer rights, legal obligations or from law applicable to the provision of  services will be resolved between the clients and Technicians. Data regarding the Fundis and their Proffesional service is available in the REKEBISHA app and receipts for gigs are sent to the email address listed in client’s profile.

<br></br><br></br>1.2 . The CLIENT (you) enters into a contract with a Rekebisha Technician for the provision of services via the Rekebisha App. Depending on the payment options supported for given task, you can choose whether to pay the technician for the repair service in cash or use REKEBISHA IN-App Payment. Charges will be inclusive of applicable taxes where required by law. Charges may include other applicable fees, tolls, and/or surcharges including a booking fee, municipal tolls, airport surcharges or processing fees for split payments. If you wish, you may also choose to pay a Tip to the technician  directly or via the use of Rekebisha in-App Payment. We may limit the maximum value of a Tip at our sole discretion.

<br></br><br></br>1.3 During the installation of REKEBISHA app, client's mobile number is linked to the respective REKEBISHA user account and added to our database. If you are no longer using your mobile number, you must notify REKEBISHA within 7 days so we can anonymize your account data. If you do not notify us about any change to your number, your mobile operator may issue the same mobile number to a new person who when using the REKEBISHA App then may have access to your data.

<div className='text-lg font-semibold py-4'>2. Promotional Codes</div>

2.1 REKEBISHA may send you promotional codes on a per promotion basis. Promotional code credit can be applied towards payment on completion of a task or other features or benefits related to the service and/or a Third Party’s service and are subject any additional terms that are established on a per promotional code basis. Expiration dates of promo codes will be reflected in-app once you have applied the promo code to your account.

<br></br><br></br>2.2 REKEBISHA reserves the right to cancel any promotional code at any time for any reason. This includes, but is not limited to, if REKEBISHA deems that codes are being used in an unlawful or fraudulent manner, those issued mistakenly, and those which have expired.

<div className='text-lg font-semibold py-4'>3. REKEBISHA In-App Payment</div>

3.1 Depending on the payment options supported for the given task, You can pay for the Rekebisha services with a card, mobile carrier billing or other payment methods) as and when available through REKEBISHA App. By providing Rekebisha in-App Payment service, Rekebisha acts as commercial agent for the providers of the tprofessional services. Every technician has authorised REKEBISHA as their commercial agent for the mediation of conclusion of contracts between the Technician  and the Clientr, including the power to accept payments from the client and to forward the payments to the technicianr. Your obligation to the provider of the repair service will be fulfilled when the payment order is given to transfer funds to REKEBISHA’s bank account. You, as a client are responsible for ensuring that the payment takes place and ensuring that sufficient funds are available.

<br></br><br></br>3.2 You may choose to pay a Tip to the Technician using the REKEBISHA In-app Payment service. The Tip can be paid via the In-app Payment by means authorised by Rekebisha  for that purpose. REKEBISHA will not hold a commission for the brokerage of the Tip and the Tip will be transferred to the technician in full amount, excluding any taxes, if apapplicable.REKEBISHA reserves the right to withhold the Tip, if the paymen the Tip is suspected as being fraudulent, illegal, for a purpose other than as a gratuity related to the service provided or used in conflict with Rekebisht’s Terms and Conditions

<br></br><br></br>3.3 When making payments by Rekebisha in-App Payment, Rekebisha receives your payments and forwards money to the technician. Rekebisha may ask additional data from you to verify payment method.

<br></br><br></br>3.4 When making payments by Rekebisha in-App Payment Services, REKEBISHA is not responsible for possible third-party payment costs (e.g mobile operators, bank fees). These service providers may charge you additional fees when processing payments in connection with the Rekebisha in-App Payment. REKEBISHA is not responsible for any such fees and disclaims all liability in this regard. Your payment method may also be subject to additional terms and conditions imposed by the applicable third-party payment service provider; please review these terms and conditions before using your payment method.

<br></br><br></br>3.5 Rekebisha will be responsible for the functioning of Rekebisha in-App Payment and provide support in resolving problems. The resolution of disputes related to Rekebisha in-App Payment also takes place through us. For payment support service please contacttowww.rekebisha.com Inquiries submitted by e-mail or Rekebisha App will receive a response within one business day. Rekebisha will resolve Rekebisha in-App Payment related complaints and applications within two business days.

<br></br><br></br>3.6. Upfront Fees. You may be offered to use a service option that allows you to agree to a fixed price for a given instance of Rekebisha service provided by the Technician(i.e Upfront Charge). Upfront Charge is communicated to you via the Rekebisha App when the service is requested. Upfront Charge shall not be applied if you change the service during the request and in a case where  the service takes materially longer than estimated due various factors, or when other unexpected circumstances impact the characteristics of the service materially (e.g a repair requiring more material than is expected)

<div className='text-lg font-semibold py-4'>4. Ordering and cancelling repair services</div>

4.1 If you order a repair service and the technician has agreed to undertake the work then the professional service is considered to be ordered.

<br></br><br></br>4.2 Once a technician confirms that he/she will complete your task, you will enter into a separate agreement with the technician for the provision of the service on such terms and conditions as you agree with the technician.Rekebisha  does not provide technical services and is not a party to your agreement with the relevant technician.

<br></br><br></br>4.3 Cancelling the use of an ordered service is considered to be the situation where the technician has replied to your request and you subsequently reject, cancel or refuse the repair service. When a repair service request is cancelled after certain time period you are required to pay a cancellation fee.

<br></br><br></br>4.4 If you cancel a repair service request on multiple successive instances within 24-hour we may temporarily block your account for warning. After multiple such warnings, we may suspend your account for longer period (e.g 6 months). After that period you could ask to reactivate your account and your application will be reviewed by Rekebisha.

<br></br><br></br>4.5 When technician notifies the client about the scope  of fulfillment of the task for the specific service requested will not be achieved  within certain time period as specified in the Rekebisha App, the request will be deemed cancelled. Sometimes technician may decide to cancel your request, please note that Rekebisha is not responsible for such situations.

<br></br><br></br>4.6 Once the technician arrives and sends you a notification that he/she has arrived the Rekebisha app may begin charging a fee on a waiting time basis according to the rates specified in the Rekebisha app.

<br></br><br></br>4.7 If you have requested repair services using the Rekebisha app and cause damage to the technicians tools of service ,the technician will have the right to require you to pay a penalty of 50 USD  and require compensation for any damages exceeding the penalty. If you do not pay the penalty and/or compensate the damage, Rekebisha may pursue the claims on behalf of the provider of the Rekebisha service.

<div className='text-lg font-semibold py-4'>5. License to use RekebishaApp</div>

5.1 As long as you comply with these General Terms and Conditions, we agree to grant you a royalty free, revocable, non-exclusive, right to access and use the Rekebisha app in accordance with these General Terms and Conditions, the Privacy Notice and the applicable app-store terms. You may not transfer or sub-license this right to use the Rekebisha app. In the event that your right to use Rekebisha app is cancelled, the corresponding non-exclusive license will also be cancelled.

<div className='text-lg font-semibold py-4'>6. Liability</div>

6.1 As the Rekebisha app is an information society service (a means of communication) between Professionals and clients, we cannot guarantee or take any responsibility for the quality or the absence of defects in the provision of professional services. As the usage of  Rekebisha repair services depends on the behaviour of the technicians,Rekebisha does not guarantee that you will always have offers available for the provision of the repair services.

<br></br><br></br>6.2 The Rekebisha app does not offer or broker repair services for clients. It is also not a repairs agency service for finding repair services for professional service providers. The Rekebisha app is used as the means for organising the provision of repair services.

<br></br><br></br>6.3 The consumer's right of refund is not applied to Rekebisha app orders. Requesting a refund from the Rekebisha  service does not withdraw you from the agreement in the course of which the provision of the repair service was ordered.

<br></br><br></br>6.4 The Rekebisha app is provided on an "as is" and “as available” basis. Rekebisha does not represent, warrant or guarantee that access to Rekebisha app will be uninterrupted or error free. In case of any faults in the software, we will endeavour to correct them as soon as possible, but please keep in mind that the functioning of the app may be restricted due to occasional technical errors and we are not able to guarantee that the app will function at all times, for example a public emergency may result in a service interruption.

<br></br><br></br>6.5 Rekebisha, its representatives, directors and employees are not liable for any loss or damage that you may incur as a result of using Rekebisha app or relying on, the task contracted for through the Rekebesha app, including but not limited to:

<br></br><br></br>6.5.1. any direct or indirect property damage or monetary loss;

<br></br><br></br>6.5.2. loss of profit;

<br></br><br></br>6.5.3. loss of business, contracts, contacts, goodwill, reputation and any loss that may arise from interruption of the business;

<br></br><br></br>6.5.4. loss or inaccuracy of data; and

<br></br><br></br>6.5.5. any other type of loss or damage.

<br></br><br></br>6.6 The financial liability of Rekebisha in connection with breach of the contract will be limited to 500 USD. You will have the right to claim for damages only if REKEBISHA has deliberately violated the contract. REKEBISHA will not be liable for the actions or inactions of the technician and will not be liable for damages that the technician causes to the clients.

<br></br><br></br>6.7 You agree to fully indemnify and hold Rekebisha, their affiliate companies, representatives, employees and directors harmless from any claims or losses (including liabilities, damages, costs and expenses of any nature) that they suffer as a result of your use of the Rekebisha app (including the taks you obtain through your use of the Rekebisha app).

<br></br><br></br>6.8 Rekebisha  may immediately end your use of the Rekebisha app if you breach these General Terms and Conditions or we consider it necessary to protect the integrity of Rekebisha or the safety of technicians.

<div className='text-lg font-semibold py-4'>7. Good practice using the Rekebisha app</div>

<br></br><br></br>7.1 As Rekebish is not a provider or broker of the repair services, any issues with defects or quality of the services provided will be resolved in accordance with the rules and regulations of the technician or the relevant public authority.

<br></br><br></br>7.2 We ask to fill out a feedback form in the Rekebisha app. This enables us to offer suggestions to the technicians for improving the quality of their service.

<br></br><br></br>7.3 We expect that you use Rekebisha app in good faith and be respectful of the technicians who offer their services through Rekebisha app.Rekebisha retains the right to close your account if you have violated the terms set out in this General Terms and Conditions or if your activities are malicious, i.e. withholding payment for the provision of the  service, fraud, being disrespectful towards the technicians, etc. In these cases, your Rekebisha app account may be revoked without prior notice.

<br></br><br></br>7.4 Rekebisha will make every effort to ensure that only technicians, who have integrity and are respectful of their profession and clientss, use the Rekebisha app. However, we are in no position to guarantee that every provider of technical services, located by the Rekebisha app, satisfies the aforementioned criteria at all times. If you experience objectionab to the technical service, please notify the company responsible for the service, a supervisory authority or our customer support.

<div className='text-lg font-semibold py-4'>8. Amendments to the General Terms and Conditions</div>

8.1 If any substantial amendments are made to the General Terms and Conditions, then you will be notified by e-mail or Rekebisha app notifications. If you continue using Rekebisha app, you will be deemed to accept the amendments.

<div className='text-lg font-semibold py-4'>9. Final Provisions</div>

The General Terms and Conditions will be governed by and construed and enforced in accordance with the laws of Republic of Kenya. If the respective dispute resulting from General Terms or Agreement could not be settled by the negotiations, then the dispute will be finally solved in  a court of Law  in the Republic of Kenya. If any provision of the General Terms is held to be unenforceable, the parties will substitute for the affected provision an enforceable provision that approximates the intent and economic effect of the affected provision.

<br></br><br></br>Date of entry into force: 21.08.2023

        </p>
        </div>
        <MainFooter/>
    </div>
  )
}
