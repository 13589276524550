import React, { useEffect, useRef } from "react";
import MainHeader from "../../Components/Header/MainHeader";
import MainFooter from "../../Components/Footer/MainFooter";
import { auth, db, storage } from "../../Database/RekebishaAdmin/config";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../Modals/Profile/EditProfile";
import { useState } from "react";
import QRCode from "qrcode.react"; // Import the QRCode library
import { Rating } from "primereact/rating";
import { Toast } from "primereact/toast";

export default function EditablePortfolio() {
  const navigate = useNavigate();
  const toast = useRef(null);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [professionalbody, setProfessionalBody] = useState("");
  const [profession, setProfession] = useState("");
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [readOnlyUrl, setreadOnlyUrl] = useState("");
  const [avatarurl, setAvatarUrl] = useState("");
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        const snapshot = await db
          .collection("FundiAppUsers")
          .doc(auth.currentUser.uid)
          .collection("Posts")
          .get();
        const items = snapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          return { id, ...data };
        });
        setPortfolioItems(items);
      }
    };

    fetchData();
  }, [auth.currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/editableportfolio");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  /*Get the users Information*/
  const getUserDetails = async () => {
    try {
      const doc = await db
        .collection("FundiAppUsers")
        .doc(auth.currentUser.uid)
        .get();

      if (doc.exists) {
        const data = doc.data();
        const firstname = data.firstname;
        const lastname = data.lastname;
        const professionalBody = data.professionalbody;
        const profession = data.profession;
        const avatarurl = data.profileimage;
        const rating = data.competenceRating;

        setAvatarUrl(avatarurl);
        setFirstName(firstname);
        setLastName(lastname);
        setProfessionalBody(professionalBody);
        setProfession(profession);
        setRating(rating);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting user details:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserDetails();
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe when the component unmounts
    };
  }, []);

  const generateReadOnlyUrl = () => {
    // Generate a unique identifier for the shared portfolio
    const sharedId = auth.currentUser.uid; // You might want to use something more unique

    // Construct the read-only URL
    const readOnlyUrl = `${window.location.origin}/find-fundi/shared/${sharedId}`;

    // Display the URL to the user (you can use a modal or a popup)
    setreadOnlyUrl(readOnlyUrl);
    setPopupVisible(true);
    // Display the QR code
    setQrCodeVisible(true);
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    auth
      .signOut()
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => alert(error.message));
  };

  const copyUrlToClipboard = () => {
    const textArea = document.createElement("textarea");
    textArea.value = readOnlyUrl;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert("URL copied to clipboard!");
    setPopupVisible(false);
  };

  // Function to delete a portfolio item by its key
  const handleDelete = async (itemId) => {
    try {
      await db
        .collection("FundiAppUsers")
        .doc(auth.currentUser.uid)
        .collection("Posts")
        .doc(itemId)
        .delete();
      setPortfolioItems((prevItems) =>
        prevItems.filter((item) => item.id !== itemId)
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Item deleted successfully",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong. Try again later.",
        life: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <MainHeader />
      <div className="flex flex-row  flex-wrap gap-4 flex-1 py-10 sm:px-12 px-4">
        <div className="flex flex-col gap-4 sm:w-1/4 w-full">
          <div className="flex flex-col items-center gap-4 p-4 bg-white border border-gray-300 rounded shadow">
            <div className="relative">
              {avatarurl ? (
                <img
                  src={avatarurl}
                  alt="Image"
                  className="rounded-full object-cover bg-gray-50 shadow border-gray-500 h-32 w-32 mb-5"
                ></img>
              ) : (
                <div className="flex items-center justify-center w-full h-40 bg-gray-300 sm:w-40 rounded-full dark:bg-gray-700">
                  <svg
                    className="w-12 h-12 rounded-full text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center gap-3">
              {firstname && lastname ? (
                <h6 className="text-xl text-black font-semibold capitalize">{`${firstname} ${lastname}`}</h6>
              ) : (
                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
              )}
              {profession ? (
                <div className="text-[11px] bg-gray-600 py-1 px-2 rounded font-semibold capitalize text-white">
                  {profession}
                </div>
              ) : (
                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
              )}
              {professionalbody ? (
                <h6 className="text-sm text-center text-black capitalize">
                  {professionalbody}
                </h6>
              ) : (
                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
              )}
              <Rating className="py-2" value={rating} disabled cancel={false} />
              <button
                className="py-2 bg-[#8cc740] text-white w-full rounded"
                onClick={generateReadOnlyUrl}
              >
                Share
              </button>
              <button
                className="py-2 bg-red-700 text-white w-full rounded"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-4 sm:mt-0 mt-12">
          <div className="flex flex-row items-center justify-between sm:mb-0 mb-12">
            {firstname && (
              <h1 className="sm:text-2xl text-xl font-bold">{`${firstname}'s Portfolio`}</h1>
            )}
            <button className="bg-black py-2 px-4 rounded text-sm text-white">
              <Link to="/uploadscreen" style={{ textDecoration: "none" }}>
                Upload New Image
              </Link>
            </button>
          </div>
          {portfolioItems.length == 0 ? (
            <div className="flex flex-col items-center text-center gap-4 bg-white rounded border py-8 border-gray-300 shadow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-24 h-24 text-gray-300"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
              <h2 className="text-lg text-gray-500">No content found!</h2>
              <button className="bg-black py-2 px-4 rounded text-sm text-white">
                <Link to="/uploadscreen" style={{ textDecoration: "none" }}>
                  Upload New
                </Link>
              </button>
            </div>
          ) : (
            <div className="flex flex-row gap-4 flex-wrap">
              {portfolioItems.map((items, index) => (
                <div className="flex flex-col" key={index}>
                  <figure className="relative max-w-sm transition-all duration-300 cursor-pointer">
                    <a href={items.imageUrl} target="_blank">
                      <img
                        className="w-120 h-120 object-cover"
                        src={items.imageUrl}
                        alt=""
                      />
                    </a>
                    <figcaption className="absolute px-4 bg-black text-lg text-white bottom-0 py-4 opacity-80 w-full">
                      <div className="flex flex-row flex-no-wrap justify-between">
                        <h5 className="capitalize text-sm truncate">
                          {items.title}
                        </h5>
                      </div>
                    </figcaption>
                  </figure>
                  <div
                    onClick={() => handleDelete(items.id)}
                    className="bg-black hover:bg-gray-700 text-white text-center mt-2 py-2 w-full cursor-pointer"
                  >
                    Delete
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Popup onClose={closePopup} isOpen={isPopupOpen} />

        {popupVisible && (
          <div className="popup">
            <div className="flex flex-col justify-center items-center gap-4">
              {qrCodeVisible && (
                <div className="qrCode flex flex-col items-center gap-4">
                  <QRCode value={readOnlyUrl} />
                  <p className="text-white">{readOnlyUrl}</p>
                </div>
              )}
              <button
                className="py-2 bg-green-400 text-white w-full rounded"
                onClick={copyUrlToClipboard}
              >
                Copy URL
              </button>
            </div>
          </div>
        )}
      </div>
      <MainFooter />
    </div>
  );
}
