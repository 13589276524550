import { Rating } from 'primereact/rating';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../Database/RekebishaAdmin/config';

const UserCard = ({ id, firstname, lastname, email, profession, profileImageUrl, phoneNumber, rating }) => {
    return (
        <Link to={`/find-fundi/shared/${id}`} className="border p-4 sm:w-[300px] hover:shadow-xl cursor-pointer bg-white flex flex-col items-center w-full gap-1 rounded-lg">
            {profileImageUrl ?
                <img src={profileImageUrl} alt="Image" className="rounded-full object-contain bg-white shadow border-gray-500 h-48 w-48 sm:h-24 sm:w-24 mb-5"></img>
                :
                <div role='status' className='rounded-full object-contain bg-gray-400 shadow border-gray-500 h-32 w-32 mb-5 animate-pulse'></div>
            }
            {firstname && lastname ? <div className="capitalize font-semibold">{`${firstname} ${lastname}`}</div> : <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>}
            {profession ? <div className="text-[11px] bg-gray-600 py-1 px-2 rounded font-semibold text-white">{profession}</div> : <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>}
            <Rating className="py-2" value={rating} disabled cancel={false} />
            {email ? <div className="text-[13px] lowercase">{email}</div> : <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>}
            {phoneNumber ? <div className="text-[13px]">{phoneNumber}</div> : <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>}
        </Link>
    );
}

export default UserCard;