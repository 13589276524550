import React, { useState } from 'react';
import {ImCancelCircle} from 'react-icons/im'
import './EditProfile.css'
import { auth, db } from '../../Database/config';

const Popup = ({ onClose, isOpen }) => {

    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [profession, setProfession] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [overview, setOverView] = useState("");

  if (!isOpen) return null;

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!firstname || !lastname || !profession || !phonenumber || !address || !overview) {
      alert("Please fill in all fields before updating.");
      return;
  }

    try {
        await db.collection('users').doc(auth.currentUser.uid).update({
          firstname: firstname,
          lastname: lastname,
          address: address,
          phonenumber: phonenumber,
          overview: overview,
          profession:profession
        }
        );
        console.log('Document successfully updated!');
    } catch (error) {
        console.error('Error updating document: ', error);
    }
};


  return (
    <div className="popup">
      <div className="popup-content">
        {/* Your popup content goes here */}
        <div className='popupHeader'>
            <h1 className='popupTitle'>
                Edit Profile
            </h1>
            <ImCancelCircle className='popupIcon' onClick={onClose} />
        </div>
        <hr/>
        <div className='form'>
            <div className='inputs'>
                <input value={firstname}  onChange={(e)=> setFirstName(e.target.value)} className='input' placeholder='[First Name]'/>
                <input value={lastname}  onChange={(e)=> setLastName(e.target.value)} className='input' placeholder='[Last Name]' />
            </div>
            <div className='inputs'>
                <input value={profession}  onChange={(e)=> setProfession(e.target.value)} className='input' placeholder='[Profession]'/>
                <input value={phonenumber}  onChange={(e)=> setPhoneNumber(e.target.value)}  className='input' placeholder='[Phone Number]' />
            </div>
            <div className='inputs'>
                <input value={address}  onChange={(e)=> setAddress(e.target.value)} className='input' placeholder='[Location/Address]'/>
                <input value={overview}  onChange={(e)=> setOverView(e.target.value)} className='input' placeholder='[Overview]' />
            </div>
        </div>
        <hr/>
        <button onClick={handleFormSubmit} className='popupBtn'>Update</button>
      </div>
    </div>
  );
};

export default Popup;
