import MainFooter from "../../Components/Footer/MainFooter";
import MainHeader from "../../Components/Header/MainHeader";

const DotDot = () => {
    return (
        <div className='flex flex-col min-h-screen'>
            <MainHeader />
            <div className='flex flex-col justify-center items-center flex-1 py-20 px-4'>
                <div className="flex flex-col items-center w-full gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-gray-300">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    <div className="text-gray-500">We are currently registering emergency response service providers.</div>
                </div>
            </div>
            <MainFooter />
        </div>
    );
}

export default DotDot;