import MainFooter from "../../Components/Footer/MainFooter";
import MainHeader from "../../Components/Header/MainHeader";

const ContactUs = () => {
    return (
        <div className='flex flex-col min-h-screen'>
            <MainHeader />
            <div className='flex flex-col justify-center items-center flex-1 py-20 px-4'>
                Contact Us Page Content
            </div>
            <MainFooter />
        </div>
    );
}

export default ContactUs;