import MainFooter from "../../Components/Footer/MainFooter";
import MainHeader from "../../Components/Header/MainHeader";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState, useMemo } from "react";
import { db } from "../../Database/RekebishaAdmin/config";
import UserCard from "../../Components/UserCard";
import countiesData from "../../data/county.json";

const FindFundi = () => {
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [selectedSubCounty, setSelectedSubCounty] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(9);
  const [emptyData, setEmptyData] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [counties, setCounties] = useState([]);
  const [subCounties, setSubCounties] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const getAllSubCounties = useMemo(() => {
    return () => {
      const county = countiesData.County.find(
        (c) => c.county_name === selectedCounty.name
      );
      if (county) {
        return county.constituencies.map((constituency) => ({
          name: constituency.constituency_name,
        }));
      }
      return [];
    };
  }, [selectedCounty]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let collectionRef = db.collection("FundiAppUsers");
        const countQuerySnapshot = await collectionRef.get();
        const totalCount = countQuerySnapshot.size;
        setTotalCount(totalCount);

        if (selectedProfession) {
          collectionRef = collectionRef.where(
            "profession",
            "==",
            selectedProfession.name
          );
        }

        if (selectedCounty) {
          collectionRef = collectionRef.where(
            "countylocation",
            "==",
            selectedCounty.name
          );
        }

        if (selectedSubCounty) {
          collectionRef = collectionRef.where(
            "subcounty",
            "==",
            selectedSubCounty.name
          );
        }

        const querySnapshot = await collectionRef
          .orderBy("competenceRating", "desc")
          .limit(limit)
          .get();

        const fetchedData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setData(fetchedData);
        setLoading(false);
        setEmptyData(fetchedData.length === 0);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        setData([]);
        setEmptyData(true);
      }
    };

    const getFilters = async () => {
      try {
        let collectionRef = db.collection("FundiAppUsers");
        const querySnapshot = await collectionRef.get();
        const uniqueProfessions = {};
        const uniqueCounties = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          uniqueProfessions[data.profession] =
            (uniqueProfessions[data.profession] || 0) + 1;
          uniqueCounties[data.countylocation] =
            (uniqueCounties[data.countylocation] || 0) + 1;
        });

        const filteredProfessions = Object.keys(uniqueProfessions).map((name) => ({
          name,
          count: uniqueProfessions[name],
        }));
        const filteredCounties = Object.keys(uniqueCounties).map((name) => ({
          name,
          count: uniqueCounties[name],
        }));

        setProfessions(filteredProfessions);
        setCounties(filteredCounties);
        setSubCounties(getAllSubCounties());
      } catch (error) {
        console.error("Error fetching filter options:", error);
      }
    };

    fetchData();
    getFilters();
  }, [limit, selectedProfession, selectedCounty, selectedSubCounty, getAllSubCounties]);

  useEffect(() => {
    if (selectedCounty) {
      setSubCounties(getAllSubCounties()); 
      setSelectedSubCounty(null); 
    } else {
      setSubCounties([]);
    }
  }, [selectedCounty, getAllSubCounties]); 

  const handleCountyChange = (e) => {
    const selectedCounty = e.value;
    setSelectedCounty(selectedCounty);
  };

  const optionTemplate = (option) => {
    return (
      <div className="flex items-center justify-between gap-2 w-full">
        <div>{option.name}</div>
        <div className="bg-orange-200 font-bold text-sm p-1 text-orange-600 rounded">{option.count}</div>
      </div>
    );
  };

  const paginate = () => {
    setLimit((prevLimit) => prevLimit + 9);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <MainHeader />
      <div className="flex flex-col flex-1 py-10 px-4">
        <div className="flex flex-wrap gap-4">
          <div className="relative sm:w-1/4 w-full">
            <div className="sticky top-5">
              <div className="flex flex-col gap-4">
                <div className="border flex flex-col rounded-lg pb-6 bg-white">
                  <div className="border-b border-gray-200 rounded-t flex items-center px-4 py-3 mb-2">
                    <div className="font-semibold text-sm">Filter by</div>
                  </div>
                  {professions && (
                    <>
                      <div className="pt-2 px-4 font-semibold text-xs">
                        Profession
                      </div>

                      <div className="flex justify-content-center py-2 px-4">
                        <Dropdown
                          value={selectedProfession}
                          onChange={(e) =>
                            setSelectedProfession(e.target.value)
                          }
                          itemTemplate={optionTemplate}
                          options={professions}
                          optionLabel="name"
                          placeholder="Select Profession"
                          showClear
                          className="w-full md:w-14rem border border-gray-300"
                        />
                      </div>
                    </>
                  )}

                  <div className="border-b border-gray-200 py-1"></div>

                  {counties && (
                    <>
                      <div className="pt-2 px-4 font-semibold text-xs">
                        County
                      </div>

                      <div className="flex justify-content-center py-2 px-4">
                        <Dropdown
                          value={selectedCounty}
                          onChange={handleCountyChange}
                          itemTemplate={optionTemplate}
                          options={counties}
                          optionLabel="name"
                          placeholder="Select County"
                          showClear
                          className="w-full md:w-14rem border capitalize border-gray-300"
                        />
                      </div>
                    </>
                  )}

                  <div className="border-b border-gray-200 py-1"></div>

                  {subCounties && (
                    <>
                      <div className="pt-2 px-4 font-semibold text-xs">
                        Sub County
                      </div>

                      <div className="flex justify-content-center py-2 px-4">
                        <Dropdown
                          value={selectedSubCounty}
                          onChange={(e) =>
                            setSelectedSubCounty(e.target.value)
                          }
                          options={subCounties}
                          optionLabel="name"
                          placeholder="Select Sub County"
                          showClear
                          className="w-full md:w-14rem border capitalize border-gray-300"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="hidden sm:flex flex-col bg-[#173044] rounded-xl">
                  <figure className="relative max-w-sm transition-all duration-300">
                    <figcaption className="flex flex-col p-6 gap-1 text-white">
                      <h5 className="capitalize font-semibold text-2xl">
                        Join Rekebisha
                      </h5>
                      <h5 className="text-sm">As a Fundi/Technician!</h5>
                      <h5 className="capitalize text-sm font-bold text-green-300 mt-4">
                        Call: 0733 212 124 / 0733 876 690
                      </h5>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col sm:items-start w-full">
            {totalCount ? (
              <div className="mb-4 text-sm capitalize">
                ({totalCount}) Total number of registered fundis
              </div>
            ) : (
              <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse"></div>
            )}
            {!emptyData ? (
              <div className="">
                {data.length > 0 ? (
                  <div className="flex flex-col justify-between w-full items-center gap-6">
                    <div className="flex flex-row flex-wrap items-center justify-between w-full gap-4">
                      {data.map((item) => (
                        <UserCard
                          key={item.id}
                          id={item.id}
                          firstname={item.firstname}
                          lastname={item.lastname}
                          email={item.email}
                          profession={item.profession}
                          profileImageUrl={item.profileimage}
                          phoneNumber={item.phonenumber}
                          rating={item.competenceRating}
                        />
                      ))}
                    </div>
                    <div className="card flex flex-col justify-content-center w-full items-center gap-3">
                      {loading && (
                        <button
                          disabled
                          type="button"
                          className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-600 cursor-pointer hover:text-white focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                        >
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            />
                          </svg>
                          Loading...
                        </button>
                      )}
                      {!loading && (
                        <button
                          type="button"
                          onClick={paginate}
                          className="py-2.5 px-5 w-full font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-600 cursor-pointer hover:text-white inline-flex justify-center items-center"
                        >
                          View More
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            ) : (
              <div className="flex flex-col items-center w-full gap-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20 text-gray-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
                <div className="text-gray-500">
                  Could not find a fundi! We are registering more.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default FindFundi;
